import React from "react";
import AccountContext from "../Context/AccountContext";
import userPool from "./userPool";
import {AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";


const AccountState = (props)=> {

  //logout
  const logout = async ()=>{
    return await new Promise((resolve, reject) =>{
      const user = userPool.getCurrentUser()
      if(user){
        user.signOut()
        resolve(user)
      } else {
        reject()
      }
    })
  }


  //get user session function
  const getSession = async ()=>{
    return await new Promise((resolve, reject) =>{
      const user = userPool.getCurrentUser()
      if (user){
        user.getSession(async (error, session) =>{
          if(error) {
            reject(error)
          } else {
            //resolve(session)
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if(err){
                  //console.log(err.message)
                  reject(err)
                } else {
                  const results = {}
                  for (let attribute of attributes) {
                    const {Name, Value} = attribute
                    results[Name] = Value
                  }
                  resolve(results)
                  //console.log("RESULTS:", results)
                }
              })
            })
            resolve({user, ...session, ...attributes})
          }
        })
      } else {
        reject()
      }
    })
  }

  // user registration


  //user login
  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool: userPool
      })

      const authDetails = new AuthenticationDetails({
        Username,
        Password
      })

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("Login Success", data)
          resolve(data)
        },
        onFailure: (err) =>{
          console.log("Failure", err.message)
          reject(err)
        },
        newPasswordRequired: (data) =>{
          console.log("New Password required", data)
          resolve(data)
        }
      })
    })
  }


  return(
    <AccountContext.Provider value={{authenticate, getSession, logout}}>
      {props.children}
    </AccountContext.Provider>
  )
}

export default AccountState;
