import React, {useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import AccountContext from "../Context/AccountContext";


const Logout = () => {

    // All'interno del componente:
    const navigate = useNavigate();

    const context = useContext(AccountContext)
    const {logout} = context


    useEffect(() => {

        logout()
            .then(data => {
                console.log("Logged Out Successfully")
                localStorage.clear();
                navigate('/login');
                window.location.reload()
            })
            .catch(err => {
                console.log(err)
                localStorage.clear();
                navigate('/');
                window.location.reload()
            })

    },[logout, navigate]);

    return (
        <div>
            logging out...
        </div>
    );
};

export default Logout;
