import React from "react";
import Header from "./components/Header";
import Home from "./components/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import AccountState from "./States/AccountState";
import Logout from "./components/Logout";
import Unsubscribe from "./components/Unsubscribe";
import { formatInTimeZone, toDate } from 'date-fns-tz';

function App() {
    const getLocalTimeAsCEST = () => {
        const timeZone = 'Europe/Rome'; // CEST timezone
        const startDateStr = '2024-05-13T10:00:00+0200'; // Target time in ISO format with timezone
        const endDateStr = '2024-05-26T23:59:59+0200'; // Target time in ISO format with timezone
        //const deadlineStr = '2024-04-24T16:17:00+0200'; // Target time in ISO format with timezone
        const startDate = toDate(startDateStr, { timeZone }); // Convert string to Date object in target timezone
        const endDate = toDate(endDateStr, { timeZone }); // Convert string to Date object in target timezone
        const now = new Date();
        const nowInCEST = formatInTimeZone(now, timeZone, 'yyyy-MM-dd HH:mm:ssXXX'); // Format now to CEST
        const nowDateInCEST = toDate(nowInCEST, { timeZone }); // Convert formatted string back to Date object in CEST
        return nowDateInCEST >= startDate && nowDateInCEST <= endDate;
    };

    const showHome = getLocalTimeAsCEST();

    return (
        <BrowserRouter>
            <AccountState>
                <Header />
                <div className="App">
                    <Routes>
                        {showHome && <Route exact path="/" element={<Home />} />}
                        {/*showHome && <Route exact path="/login" element={<Login />} /> */}
                        <Route path="/unsubscribe/*" element={<Unsubscribe />} />
                        <Route exact path="/logout" element={<Logout />} />
                    </Routes>
                </div>
                <Footer />
            </AccountState>
        </BrowserRouter>
    );
}

export default App;
