import React, {useEffect, useState} from 'react';

const Home = () => {
    // Inizializzazione della variabile di stato formData con i campi del form vuoti o valori predefiniti
    const [formData, setFormData] = useState({
        nome: '',
        cognome: '',
        giornoNascita: '0', // '0' come valore predefinito
        meseNascita: '0',
        annoNascita: '0',
        email: '',
        emailConferma: '',
        privacy1: false,
        privacy2: false,
        username: localStorage.getItem("username"),
        eighteenCheck: false,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        campagna: "uk"
    });

    const [submitted, setSubmitted] = useState(false)
    const [hideSuccess, setHideSuccess] = useState(true)
    const [hideError, setHideError] = useState(true)

    const [formErrors, setFormErrors] = useState({});
    const [submitAttempted, setSubmitAttempted] = useState(false)

    const [giorniDisponibili, setGiorniDisponibili] = useState([]);
    const [mesiDisponibili, setMesiDisponibili] = useState([]);

    const anni = [];

    for (let anno = 2006; anno >= 1944; anno--) {
        anni.push(anno);
    }
    const [anniDisponibili, setAnniDisponibili] = useState([]);


    const isLeapYear = (year) => {
        return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
    };


    useEffect(() => {
        // Aggiorna i giorni disponibili in base al mese e all'anno selezionati
        const aggiornaGiorni = () => {
            let numeroDiGiorni = new Date(formData.annoNascita, formData.meseNascita, 0).getDate();

            // Controllo specifico per Febbraio in caso di anno bisestile
            if (formData.meseNascita === '2') {
                const anno = parseInt(formData.annoNascita);
                if (isLeapYear(anno)) {
                    numeroDiGiorni = 29; // Febbraio di un anno bisestile ha 29 giorni
                }
            }

            setGiorniDisponibili(Array.from({ length: numeroDiGiorni }, (_, i) => i + 1));
        };

        // Aggiorna i mesi disponibili in base al giorno selezionato
        const aggiornaMesi = () => {
            const mesiCon31Giorni = [1, 3, 5, 7, 8, 10, 12]; // Gennaio, Marzo, Maggio, Luglio, Agosto, Ottobre, Dicembre
            const mesiCon30Giorni = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

            const giornoSelezionato = parseInt(formData.giornoNascita);
            if (giornoSelezionato === 31) {
                setMesiDisponibili(mesiCon31Giorni);
            } else if (giornoSelezionato === 30) {
                setMesiDisponibili(mesiCon30Giorni);
            } else {
                setMesiDisponibili(Array.from({ length: 12 }, (_, i) => i + 1)); // Tutti i mesi
            }
        };

        const aggiornaAnni = () => {
            // Verifica se l'utente ha selezionato il 29 Febbraio
            if (formData.giornoNascita === '29' && formData.meseNascita === '2') {
                // Filtra gli anni per mantenere solo quelli bisestili
                const anniBisestili = anni.filter(isLeapYear);
                setAnniDisponibili(anniBisestili);
            } else {
                // Resetta la lista completa degli anni se non è selezionato il 29 Febbraio
                setAnniDisponibili(anni);
            }
        };


        aggiornaGiorni();
        aggiornaMesi();
        aggiornaAnni()
    }, [formData.giornoNascita, formData.meseNascita, formData.annoNascita]);




    const utenteInserito = {}


    let hideMoleLoveStudentGraphics = false
    if(localStorage.getItem("username"))  hideMoleLoveStudentGraphics = true

    // aggiornamenti dei campi del form
    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        // Aggiorna il valore del campo nel formData
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));

        // Re-validazione condizionale se un tentativo di invio è stato già fatto
        if (submitAttempted) {
            let newError = '';

            // Validazione per il campo "nome"
            if (name === 'nome' && !value.trim()) {
                newError = 'Il nome è obbligatorio.';
            }

            // Validazione per il campo "cognome"
            if (name === 'cognome' && !value.trim()) {
                newError = 'Il cognome è obbligatorio.';
            }

            // Validazione per il campo "email"
            if (name === 'email') {
                if (!value.trim()) {
                    newError = 'Il campo Email è obbligatorio.';
                } else if (!/\S+@\S+\.\S+/.test(value)) {
                    newError = 'Il formato dell\'Email non è valido.';
                }
            }

            // Validazione per il campo "emailConferma"
            if (name === 'emailConferma') {
                if (value !== formData.email) {
                    newError = 'Le email non corrispondono.';
                }
            }

            // Aggiorna l'oggetto formErrors basato sulla re-validazione
            setFormErrors(prevErrors => ({
                ...prevErrors,
                [name]: newError,
            }));
        }
    };



    const validateForm = () => {
        let errors = {};
        let formIsValid = true;

        if (!formData.nome.trim()) {
            errors.nome = "The Name in mandatory.";
            formIsValid = false;
        }

        if (!formData.cognome.trim()) {
            errors.cognome = "The Surname is mandatory.";
            formIsValid = false;
        }

        if (!formData.email.trim()) {
            errors.email = "The email is mandatory.";
            formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = "The email format is invalid.";
            formIsValid = false;
        }

        if (formData.email !== formData.emailConferma) {
            errors.emailConferma = "The emails don't match.";
            formIsValid = false;
        }

        setFormErrors(errors);
        return formIsValid;
    };



    const handleSubmitData = (e) => {
        e.preventDefault();
        setSubmitted(true)
        setSubmitAttempted(true);

        if (!validateForm()) {
            //console.log("The form is not valid");
            setSubmitted(false)
            return;
        }


        // Assicurati che il form sia valido prima di procedere
        if (e.target.checkValidity()) {
            // Preparazione dei dati da inviare
            const dataToSend = {
                Item: formData
            };
            const json = JSON.stringify(dataToSend);

            //console.log(json); // Debug: Stampa il JSON da inviare
            const myJwt = localStorage.getItem("jwt")
            // Configurazione della chiamata fetch
            fetch("https://api.moleskinelovestudents.com/v1/user", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                    'Connection': 'keep-alive',
                    'Authorization': `Bearer ${myJwt}`
                },
                referrerPolicy: 'strict-origin-when-cross-origin',
                body: json
            })
                .then(response => {
                    const status = response.status;
                    //console.log(status); // Debug: Stampa lo stato della risposta

                    if (status === 400) {
                        // Logica per gestire la risposta 400
                        // Ad esempio, mostrare un messaggio all'utente
                        // Utilizza lo stato del componente per mostrare/nascondere i messaggi
                        setHideError(false)
                    }

                    if (status === 200) {
                        setHideSuccess(false)
                        // Logica per gestire la risposta 200
                        // Ad esempio, mostrare un messaggio di successo
                        // Anche qui, utilizza lo stato del componente per mostrare/nascondere i messaggi
                    }
                    return response.json();
                })

                .catch((error) => {
                    console.error('Error:', error); // Gestione degli errori
                    setHideError(false)
                    setSubmitted(false)
                })
                .finally(()=>{
                    setSubmitted(false)
                    window.scrollTo(0, 0);
                });
        } else {
            //console.log("Form is not valid"); // Debug: Il form non è valido
            setSubmitted(false)
        }
    };
    return (
        <>
            <main className="d-flex mx-auto mt-5 mb-5 pt-5 align-items-center">
                <article className="container">
                    {
                        //<!-- SE UTENTE INSERITO CORRETTAMENTE -->
                    }
                    <div id="utenteInserito" className="row justify-content-center position-relative mt-5 mb-5 pt-5"
                         hidden={hideSuccess}>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="alert alert-success" role="alert">
                                <p>Congratulations <strong id="nomeInserito">{formData.nome} {
                                    utenteInserito
                                        .cognome
                                }</strong>!<br/>Ihre Daten sind korrekt eingegeben worden.</p>
                            </div>
                            <p>
                                Sie erhalten eine Bestätigungs-E-Mail an <span id={"emailDiConferma"}>{formData.email}</span>.
                            </p>
                            <p className="mt-3">
                                {
                                    localStorage.getItem("username") ?
                                        <a href="/" className="btn btn-brand">Zurück zur Startseite</a> :
                                        <a href="https://www.moleskine.com/en-gb/" target="_blank">Zur Moleskine Website gehen</a>
                                }
                            </p>
                        </div>
                    </div>
                    {
                        //<!-- FINE SE UTENTE INSERITO CORRETTAMENTE -->
                        //<!-- SE UTENTE EMAIL GIÀ PRESENTE -->
                    }
                    <div id="esistente" className="row justify-content-center position-relative mt-5 mb-5 pt-5" hidden={hideError}>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="alert alert-danger" role="alert">
                                <h4><strong>Warnung!</strong> <br/>Die E-Mail-Adresse <strong
                                    id="emailPresente">{formData.email}</strong><br/>ist bereits im System!
                                </h4>
                            </div>
                            <a href="/" className="btn btn-brand w-100">Bitte versuchen Sie es erneut, indem Sie eine andere E-Mail-Adresse eingeben.</a>
                        </div>
                    </div>
                    {
                        //<!-- FINE SE UTENTE EMAIL GIÀ PRESENTE -->
                        //<!-- FORM NORMALE -->
                    }
                    <div id="titolo" className="row justify-content-center position-relative" hidden={!hideSuccess || !hideError || hideMoleLoveStudentGraphics}>
                        <div className="col-12 text-center">
                            <h1 className="text-center text-invert fw-500 my-4 my-lg-8">
                                WERDE MITGLIED IM EXKLUSIVEN MOLESKINE STUDENTS CLUB
                            </h1>
                        </div>
                    </div>
                    <form id="theForm" className="form-inline needs-validation" noValidate action="" method=""
                          encType="multipart/form-data" hidden={!hideError || !hideSuccess}>
                        <div className="row justify-content-center position-relative mb-16 mb-lg-12">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="card">
                                    <div className="card-body p-4">
                                        <h3 className="fs-28 lh-34 fs-lg-24 lh-32 mb-4 text-invert fw-500">
                                            REGISTRIERE DICH
                                        </h3>
                                        <p>Und du erhältst:</p>
                                        <ul className="fs-18 fs-lg-16 lh-24 colored ms-lg-1 mb-3"
                                            hidden={hideMoleLoveStudentGraphics}>
                                            <li>exklusiven Zugang zu Angeboten nur für Studierende</li>
                                            <li>jedes Jahr ein besonderes Geburtstagsgeschenk</li>
                                            <li>private Einladungen zu Moleskine Veranstaltungen in deiner Nähe</li>
                                        </ul>

                                        <div className="row">
                                            <div className="col-sm-12 mb-4">
                                                <div className="form-floating">
                                                    <input type="text"
                                                           className={`form-control ${submitAttempted && !formErrors.nome ? 'is-valid' : ''} ${formErrors.nome ? 'is-invalid' : ''}`}
                                                           id="nome"
                                                           name="nome"
                                                           placeholder="Vorname *" required value={formData.nome}
                                                           onChange={handleInputChange}/>
                                                    <label htmlFor="nome">Vorname *</label>
                                                    <div className="invalid-feedback">
                                                        The Name is mandatory
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 mb-4">
                                                <div className="form-floating">
                                                    <input type="text"
                                                           className={`form-control ${submitAttempted && !formErrors.cognome ? 'is-valid' : ''} ${formErrors.cognome ? 'is-invalid' : ''}`}
                                                           id="cognome"
                                                           name="cognome" placeholder="Nachname *" required
                                                           value={formData.cognome} onChange={handleInputChange}/>
                                                    <label htmlFor="cognome">Nachname *</label>
                                                    <div className="invalid-feedback">
                                                        {formErrors.cognome}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-2 fs-16 d-flex align-items-center labelall">
                                                Geburtsdatum (optional)
                                            </div>
                                            <div className="col-sm-4 mb-4">
                                                <div className="form-floating">
                                                    <select className="form-select" id="giornoNascita"
                                                            name="giornoNascita" aria-label="DD"
                                                            value={formData.giornoNascita} onChange={handleInputChange}>
                                                        <option value="0">DD</option>
                                                        {Array.from({length: 31}, (_, i) => i + 1).map(giorno => (
                                                            <option key={giorno} value={giorno}
                                                                    disabled={!giorniDisponibili.includes(giorno)}>
                                                                {giorno}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="giornoNascita">Tag</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 mb-4">
                                                <div className="form-floating">
                                                    <select className="form-select" id="meseNascita" name="meseNascita"
                                                            aria-label="MM" value={formData.meseNascita}
                                                            onChange={handleInputChange}>
                                                        <option value="0">MM</option>
                                                        {Array.from({length: 12}, (_, i) => ({
                                                            value: i + 1,
                                                            label: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][i],
                                                            disabled: !mesiDisponibili.includes(i + 1)
                                                        })).map(mese => (
                                                            <option key={mese.value} value={mese.value}
                                                                    disabled={mese.disabled}>
                                                                {mese.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="meseNascita">Monat</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 mb-4">
                                                <div className="form-floating">
                                                    <select className="form-select" id="annoNascita" name="annoNascita"
                                                            aria-label="YYYY" value={formData.annoNascita}
                                                            onChange={handleInputChange}>
                                                        <option value="0">YYYY</option>
                                                        {anniDisponibili.map(anno => (
                                                            <option key={anno} value={anno}>{anno}</option>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="annoNascita">Jahr</label>
                                                </div>
                                            </div>


                                            <div className="col-sm-12 mb-4">
                                                <div className="form-floating">
                                                    <input type="email"
                                                           onCopy={(e)=>{
                                                               e.preventDefault()
                                                               return false;
                                                           }}
                                                           className={`form-control ${submitAttempted && (formErrors.email ? 'is-invalid' : formData.email.trim() && /\S+@\S+\.\S+/.test(formData.email) ? 'is-valid' : '')}`}
                                                           id="email"
                                                           name="email"
                                                           placeholder="deine Universitäts-E-Mail-Adresse *" required value={formData.email}
                                                           onChange={handleInputChange}/>
                                                    <label htmlFor="email">deine Universitäts-E-Mail-Adresse *</label>
                                                    {submitAttempted && formErrors.email &&
                                                        <div className="invalid-feedback">{formErrors.email}</div>}
                                                </div>
                                            </div>
                                            <div className="col-sm-12 mb-4">
                                                <div className="form-floating">
                                                    <input type="email"
                                                           onPaste={(e)=>{
                                                               e.preventDefault()
                                                               return false;
                                                           }}
                                                           onCopy={(e)=>{
                                                               e.preventDefault()
                                                               return false;
                                                           }}
                                                           className={`form-control ${submitAttempted && (formErrors.emailConferma ? 'is-invalid' : formData.emailConferma.trim() && formData.email === formData.emailConferma ? 'is-valid' : '')}`}
                                                           id="emailConferma"
                                                           name="emailConferma"
                                                           placeholder="E-Mail-Adresse bestätigen *" required
                                                           value={formData.emailConferma} onChange={handleInputChange}/>
                                                    <label htmlFor="emailConferma">E-Mail-Adresse bestätigen *</label>
                                                    {submitAttempted && formErrors.emailConferma && <div
                                                        className="invalid-feedback">{formErrors.emailConferma}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        <p className="mt-3 mb-4">Ich bestätige, dass ich die <a
                                            href="https://www.moleskine.com/en-gb/privacy-policy.html" target="_blank">Datenschutzrichtlinie zur Verarbeitung</a> personenbezogener Daten gelesen habe und:</p>

                                        <div className="form-check position-relative mb-3">
                                            <input type="checkbox" className="form-check-input" id="privacy1"
                                                   name="privacy1" required value={formData.privacy1}
                                                   onChange={handleInputChange}/>
                                            <label className="form-check-label" htmlFor="privacy1">Ich erkläre mich damit einverstanden, Werbenachrichten über Produkte und Dienstleistungen von Moleskine zu erhalten..</label>
                                        </div>
                                        <div className="form-check position-relative mb-3">
                                            <input type="checkbox" className="form-check-input" id="eighteenCheck"
                                                   name="eighteenCheck" required value={formData.eighteenCheck}
                                                   onChange={handleInputChange}/>
                                            <label className="form-check-label" htmlFor="eighteenCheck">Ich bestätige, dass ich über 18 Jahre alt bin.</label>
                                        </div>
                                        <div className="form-check position-relative mb-3">
                                            <input type="checkbox" className="form-check-input" id="privacy2"
                                                   name="privacy2" value={formData.privacy2}
                                                   onChange={handleInputChange}/>
                                            <label className="form-check-label" htmlFor="privacy2">Ich erkläre mich damit einverstanden, dass Moleskine ein Profil erstellt, um mir Marketingnachrichten zu schicken, die besser auf meine Vorlieben und Interessen abgestimmt sind.</label>
                                        </div>
                                        <button disabled={!(formData.privacy1 && formData.eighteenCheck && !submitted)} type="button"
                                                className="btn btn-brand w-100 mt-3" id="salva"
                                                name="salva" onClick={handleSubmitData}>Jetzt anmelden
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6" hidden={hideMoleLoveStudentGraphics}>
                                <div className="card">
                                    <img src="assets/img/moleskine-ls-image.png" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </form>

                </article>
            </main>
        </>
    );
};

export default Home;
