import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Unsubscribe = () => {
    const query = useQuery();
    const email = query.get('email');
    const token = query.get('token');
    const navigate = useNavigate();

    const [isDone, setIsDone] = useState(false)

    const [enableUnsubscribe, setEnableUnsubscribe] = useState(false)
    useEffect(() => {
        if (email && token) {
            setEnableUnsubscribe(true);
        } else {
            setEnableUnsubscribe(false);
        }
    }, [email, token]); // Dipendenze dell'effetto

    const decodedEmail = email ? decodeURIComponent(email) : "Please refer to the unsubscribe link you received via email.";
    const decodedToken = token ? decodeURIComponent(token) : "No token provided";

    const handleUnsubscribe = async () => {
        setEnableUnsubscribe(false)
        const url = "https://api.moleskinelovestudents.com/v1/unsubscribe"; // Replace with your actual URL
        const data = {
            email: decodedEmail,
            token: decodedToken
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                    'Connection': 'keep-alive',
                },
                referrerPolicy: 'strict-origin-when-cross-origin',
                body: JSON.stringify(data)
            });
            const result = await response.json();

            //console.log(response)

            if (response.status === 200) {
                //console.log("DONE")
                //console.log(result)
                setIsDone(true)
            }else {
                navigate('/unsubscribe');
                window.location.reload()
            }
        } catch (error) {
            //console.log('Error:', error)
            //console.log("ERROR")
            alert("An error occurred. Please try again.")

        }
    };

    return (
        <>
            <main className="d-flex mx-auto mt-5 mb-5 pt-5 align-items-center">
                <article className="container">
                    <div id="titolo" className="row justify-content-center position-relative">
                        <div className="col-12 text-center">
                            <form id="theForm" className="form-inline needs-validation" noValidate action="" method=""
                                  encType="multipart/form-data">
                                <div className="row justify-content-center position-relative mb-16 mb-lg-12">
                                    <div className="col-12 col-md-6 col-lg-6">
                                        <div className="card">
                                            {isDone ? (
                                                <div className="card-body p-4">
                                                    <h3 className="fs-28 lh-34 fs-lg-24 lh-32 mb-4 text-invert fw-500">
                                                        UNSUBSCRIBED!
                                                    </h3>
                                                    <p>Your email address has been successfully removed from our systems.</p>
                                                </div>
                                            ) : (
                                                <div className="card-body p-4">
                                                    <h3 className="fs-28 lh-34 fs-lg-24 lh-32 mb-4 text-invert fw-500">
                                                        UNSUBSCRIBE
                                                    </h3>
                                                    <p>Click on the "Unsubscribe" button below to stop receiving emails from us:</p>
                                                    <p><b>{decodedEmail}</b></p>
                                                    <button disabled={!enableUnsubscribe} type="button" onClick={handleUnsubscribe} className="btn btn-brand w-100 mt-3">
                                                        Unsubscribe
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </article>
            </main>
        </>
    );
};

export default Unsubscribe;
