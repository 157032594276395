import React from 'react';

const Footer = () => {

    const currentYear = new Date().getFullYear()
    return (
        <footer className="w-100 align-items-center p-3 bg-dark position-fixed bottom-0">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        Moleskine ® is a registered trademark of Moleskine Srl a socio unico.
                    </div>
                </div>

            </div>
        </footer>
    );
};

export default Footer;
