import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import AccountContext from "../Context/AccountContext";


const Header = () => {

    const context = useContext(AccountContext)
    const [username, setUsername] = useState("")
    const navigate = useNavigate();


    useEffect(() => {
        const storedUsername = localStorage.getItem("username");
        const storedJwt = localStorage.getItem("jwt")
        //console.log("JWT:", storedJwt)
        //console.log("username", storedUsername)
        if (storedUsername !== null && storedJwt === null ) {
            navigate('/logout');
        }
        if (storedUsername) {
            setUsername(storedUsername);
        }
    }, []);


    return (
        <>
            <nav className="navbar bg-dark pt-4 pb-1 position-fixed top-0 w-100" data-bs-theme="dark">
                <Link to="/" className="navbar-brand mx-auto">
                    <img src="assets/img/moleskine-logo-monogramma.png" className="img-fluid"
                         style={{maxWidth: '320px'}}/>
                </Link>
                <span hidden={username === ""} style={{position: 'absolute', top: '0', right: '0', margin: '10px', color: 'white'}}>
            {username}
        </span>
            </nav>
        </>

    );
};

export default Header;
